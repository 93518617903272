import axios from "axios";

const instance = axios.create({
  baseURL: "https://smileit.unmsapp.com/nms/api/v2.1",
  headers: {
    'x-auth-token': 'bb67659a-5cb5-47da-882d-a1554da1987b'
  }
});

export default instance;
