import { useQuery } from "react-query";
import { getSubdomain } from "@smileit/react-smile";
import backend from 'src/utils/axios';

const getAuth0ClientId = async () => {
  const subdomain = getSubdomain();
  const response = await backend.get(`/tenants/${subdomain}/`);
  const { extra_data } = response.data;
  return extra_data.auth0_client_id;
};

export const useAuth0ClientId = () => {
  return useQuery(
    'clientId',
    () => getAuth0ClientId(),
  );
};
