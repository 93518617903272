import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  jssPreset,
  StylesProvider
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  AuthProvider,
  GlobalStyles,
  SplashScreen
} from '@smileit/react-smile';
import ClearCache from 'src/components/ClearCache';
import { useAuth0ClientId } from 'src/hooks/app';
import routes, { renderRoutes } from 'src/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

const App = () => {
  const { data: auth0ClientId } = useAuth0ClientId();

  if (!auth0ClientId) {
    return (
      <SplashScreen />
    );
  }

  return (
    <StylesProvider jss={jss}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={auth0ClientId}
          redirectUri={window.location.origin + process.env.PUBLIC_URL}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          onRedirectCallback={onRedirectCallback}
        >
          <Router history={history}>
            <AuthProvider>
              <GlobalStyles />
              <ClearCache />
              {renderRoutes(routes)}
            </AuthProvider>
          </Router>
        </Auth0Provider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};

export default App;
