import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Logo } from '@smileit/react-smile';
import PublicTopBar from 'src/components/PublicTopBar.js';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const PublicLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PublicTopBar
        logo={(
          <Logo
            src={`${process.env.PUBLIC_URL}/static/logo.svg`}
            height="15px"
          />
        )}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
