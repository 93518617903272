import React from 'react';
import {
  LocalShipping as LocalShippingIcon,
  Lock as LockIcon,
  Waves as WavesIcon,
  WbSunny as WbSunnyIcon,
  Whatshot as WhatshotIcon,
  Wifi as WifiIcon,
  Opacity as OpacityIcon
} from '@material-ui/icons'
import { Droplet as DropletIcon } from 'react-feather';

export const APP_VERSION = '3.1.0';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

// Project constants
export const API_POLL_FREQ = 300000; // 5 min
export const API_SHORT_POLL_FREQ = 5000;
export const SENSOR_ICONS = {
  'FIRE_ALARM': <WhatshotIcon/>,
  'WATER_TANK': <WavesIcon/>,
  'SWITCH': <WifiIcon/>,
  'WEATHER_STATION': <WbSunnyIcon />,
  'WATER_PUMP': <OpacityIcon />,
  'SOIL_PROBE': <DropletIcon />,
  'MAGNETIC_CONTACT_SWITCH': <LockIcon />,
  'GPS_TRACKER': <LocalShippingIcon />,
};
export const EVENT_TYPE_TO_CLASS = {
  'CRITICAL': 'error',
  'WARNING': 'warning',
  'INFO': 'info',
  'OK': 'success'
};
// TODO: Remove and fetch from backend
export const SENSOR_STATE_TEXT = {
  'FIRE_ALARM': {
    'OK': 'No Alarm',
    'CRITICAL': 'Alarm Triggered',
    'NO_DATA': 'Data not received'
  },
  'WATER_TANK': {
    'OK': 'Tanks Normal',
    'WARNING': 'Tanks Low',
    'CRITICAL': 'Tanks Almost Empty',
    'NO_DATA': 'Data not received'
  },
  'SWITCH': {
    'OK': 'Online',
    'CRITICAL': 'Offline'
  },
  'WEATHER_STATION': {
    'OK': 'Online',
    'CRITICAL': 'Offline'
  },
  'SOIL_PROBE': {
    'OK': 'Online',
    'CRITICAL': 'Offline'
  },
  'MAGNETIC_CONTACT_SWITCH': {
    'OK': 'Online',
    'WARNING': 'Warning',
    'NO_DATA': 'Data not received'
  },
  'GPS_TRACKER': {
    'OK': 'Online',
    'WARNING': 'Warning',
    'NO_DATA': 'Data not received'
  }
};
