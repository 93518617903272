import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { ConditionalWrapper, Logo } from '@smileit/react-smile';

const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.background.default
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64
  }
}));

const PublicTopBar = ({
  className,
  logo,
  user,
  additionalButtons,
  menuItems,
  onMobileNavOpen,
  onLogout,
  ...rest
}) => {
  const classes = useStyles();

  const menuIcon = onMobileNavOpen ?
    (
      <Hidden lgUp>
        <IconButton
          onClick={onMobileNavOpen}
        >
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
      </Hidden>
    ) : null;

  const appLogo = logo ? logo : <Logo />;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        {menuIcon}
        <ConditionalWrapper
          condition={Boolean(onMobileNavOpen)}
          wrapper={
            children =>
              <Hidden mdDown>
                {children}
              </Hidden>
          }
        >
          <Box mr={3}>
            {appLogo}
          </Box>

          <Box>
            <img
              src={`${process.env.PUBLIC_URL}/static/sit-logo.svg`}
              height="35px"
              ml={20}
            />
          </Box>
        </ConditionalWrapper>
      </Toolbar>
    </AppBar>
  );
};

export default PublicTopBar;