import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation } from "react-query";
import { API_POLL_FREQ, API_SHORT_POLL_FREQ } from 'src/constants';
import backend from 'src/utils/axios';
import unms from 'src/utils/axios-unms';

const getSites = async (getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get('/sites/', headers);
  return response.data;
};

export const useSites = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    'sites',
    () => getSites(getAccessTokenSilently),
    {
      initialData: [],
      initialStale: true
    }
  );
};

const getSite = async (siteId) => {
  // const accessToken = await getAccessTokenSilently();
  // const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sites/${siteId}/`);
  return response.data;
};

export const useSite = (siteId) => {
  // const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['site', siteId],
    () => getSite(siteId),
    {
      initialData: {},
      initialStale: true
    }
  );
};

const getSiteSensors = async (siteId) => {
  // const accessToken = await getAccessTokenSilently();
  // const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sensors/?site=${siteId}`);
  return response.data;
};

export const useSiteSensors = (siteId, isShortInterval=false) => {
  // const { getAccessTokenSilently } = useAuth0();
  let interval = API_POLL_FREQ;
  if (isShortInterval) {
    interval = API_SHORT_POLL_FREQ;
  }

  return useQuery(
    ['sensors', siteId],
    () => getSiteSensors(siteId),
    {
      refetchInterval: interval
    }
  );
};

const getSiteCCTV = async (siteId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sensors/?_type=CCTV&site=${siteId}`, headers);
  return response.data;
};

export const useSiteCCTV = (siteId) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['sensors', siteId],
    () => getSiteCCTV(siteId, getAccessTokenSilently),
    {}
  );
};

const getUnms = async (switchId) => {
  const response = await unms.get(`/devices/${switchId}`);
  return response.data.overview;
};

export const useUnms = (switchId) => {
  return useQuery(
    ['unms', switchId],
    () => getUnms(switchId),
    {
      initialData: {},
      initialStale: true,
      enabled: Boolean(switchId),
      refetchInterval: API_POLL_FREQ
    }
  );
};

const getSiteControlledDevices = async (siteId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sensors/?site=${siteId}&controllable=true`, headers);
  return response.data;
};

export const useSiteControlledDevices = (siteId, refetchInterval = null) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['controlledDevices', siteId],
    () => getSiteControlledDevices(siteId, getAccessTokenSilently),
    {
      initialData: [],
      initialStale: true,
      refetchInterval: refetchInterval,
    }
  );
};

export const createControlRequest = async (data, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.post('/control_requests/', data, headers);
  return response.data;
};

export const useCreateControlRequest = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation((data) => createControlRequest(data, getAccessTokenSilently));
};

const getControlRequest = async (requestId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/control_requests/${requestId}/`, headers);
  return response.data;
};

export const useControlRequest = (requestId) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    [requestId],
    () => getControlRequest(requestId, getAccessTokenSilently),
    {
      refetchInterval: API_SHORT_POLL_FREQ
    }
  );
};


const getSiteWaterGraph = async (siteId, timeRange, startDate, endDate, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};

  let requestUrl = `/sites/${siteId}/water_graph/?time_range=${timeRange}`;
  if (timeRange === 'custom_range') {
    requestUrl = `/sites/${siteId}/water_graph/?time_range=custom_range&start_date=${startDate}&end_date=${endDate}`;
  }

  const response = await backend.get(requestUrl, headers);
  return response.data;
};

export const useSiteWaterGraph = (siteId, timeRange, startDate=null, endDate=null) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['waterGraph', siteId, timeRange, startDate, endDate],
    () => getSiteWaterGraph(siteId, timeRange, startDate, endDate, getAccessTokenSilently),
    {
      initialData: {
        data: null,
        labels: []
      },
      initialStale: true
    }
  );
};

const getSiteRainfallGraph = async (siteId, timeRange, startDate, endDate) => {
  // const accessToken = await getAccessTokenSilently();
  // const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};

  let requestUrl = `/sites/${siteId}/rainfall_graph/?time_range=${timeRange}`;
  if (timeRange === 'custom_range') {
    requestUrl = `/sites/${siteId}/rainfall_graph/?time_range=custom_range&start_date=${startDate}&end_date=${endDate}`;
  }

  const response = await backend.get(requestUrl);
  return response.data;
};

export const useSiteRainfallGraph = (siteId, timeRange, startDate=null, endDate=null) => {
  // const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['ranfallGraph', siteId, timeRange, startDate, endDate],
    () => getSiteRainfallGraph(siteId, timeRange, startDate, endDate),
    {
      initialData: {
        data: null,
        labels: []
      },
      initialStale: true
    }
  );
};

const getEvents = async (siteId, numRecords, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(
    `/events/?sensor__site=${siteId}&page_size=${numRecords}`,
    headers
  );
  return response.data;
};

export const useEvents = (siteId, numRecords) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['events', siteId],
    () => getEvents(siteId, numRecords, getAccessTokenSilently),
  );
};

const getEvent = async (eventId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/events/${eventId}/`, headers);
  return response.data;
};

export const useEvent = (eventId) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['event', eventId],
    () => getEvent(eventId, getAccessTokenSilently),
    {
      enabled: Boolean(eventId)
    }
  );
};

const getSiteCCTVSnapshots = async (siteId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sites/${siteId}/cctv/`, headers);
  return response.data;
};

export const useSiteCCTVSnapshots = (siteId, eventId, refetchInterval=false) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['siteCCTVSnapshots', siteId],
    () => getSiteCCTVSnapshots(siteId, getAccessTokenSilently),
    {
      enabled: Boolean(!eventId),
      refetchInterval: refetchInterval
    }
  );
};

const getEventCCTVSnapshots = async (eventId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/events/${eventId}/cctv/`, headers);
  return response.data;
};

export const useEventCCTVSnapshots = (eventId) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['eventCCTVSnapshots', eventId],
    () => getEventCCTVSnapshots(eventId, getAccessTokenSilently),
    {
      enabled: Boolean(eventId)
    }
  );
};

const getSiteSoilMoistureGraph = async (siteId, timeRange, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sites/${siteId}/soil_moisture_graph/?time_range=${timeRange}`, headers);
  return response.data;
};

export const useSiteSoilMoistureGraph = (siteId, timeRange) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['soilMoistureGraph', siteId, timeRange],
    () => getSiteSoilMoistureGraph(siteId, timeRange, getAccessTokenSilently),
    {
      initialData: {
        data: null,
        labels: []
      },
      initialStale: true
    }
  );
};

const getSensorValues = async (sensorId, numRecords, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sensor_values/?sensor=${sensorId}&page_size=${numRecords}`, headers);
  return response.data;
};

export const useSensorValues = (sensorId, numRecords) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['sensorValues', sensorId],
    () => getSensorValues(sensorId, numRecords, getAccessTokenSilently),
    {
      enabled: Boolean(sensorId)
    }
  );
};

const getSoilCsv = async (sensorId, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};
  const response = await backend.get(`/sensor_values/?sensor=${sensorId}&format=csv`, headers);
  return response.data;
};

export const useSoilCsv = (sensorId) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['soilCsv', sensorId],
    () => getSoilCsv(sensorId, getAccessTokenSilently),
    {
      enabled: Boolean(sensorId)
    }
  );
};


const getDoorOpenGraph = async (siteId, timeRange, startDate, endDate, getAccessTokenSilently) => {
  const accessToken = await getAccessTokenSilently();
  const headers = {headers: {'Authorization': `Bearer ${accessToken}`}};

  let requestUrl = `/sites/${siteId}/door_open_graph/?time_range=${timeRange}`;
  if (timeRange === 'custom_range') {
    requestUrl = `/sites/${siteId}/door_open_graph/?time_range=custom_range&start_date=${startDate}&end_date=${endDate}`;
  }

  const response = await backend.get(requestUrl, headers);
  return response.data;
};

export const useDoorOpenGraph = (siteId, timeRange, startDate=null, endDate=null) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ['waterGraph', siteId, timeRange, startDate, endDate],
    () => getDoorOpenGraph(siteId, timeRange, startDate, endDate, getAccessTokenSilently),
    {
      initialData: {
        data: null,
        labels: []
      },
      initialStale: true
    }
  );
};
