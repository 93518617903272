import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import '@smileit/react-smile/src/assets/css/prism.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import { SmileApp, SettingsProvider } from '@smileit/react-smile';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import App from 'src/App';

enableES5();

const queryClient = new QueryClient();

ReactDOM.render(
  <SmileApp>
    <QueryClientProvider client={queryClient}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </QueryClientProvider>
  </SmileApp>,
  document.getElementById('root')
);

serviceWorker.unregister();
