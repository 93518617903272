import axios from "axios";
import { getSubdomain } from "@smileit/react-smile";
import { history } from 'src/App';

const subdomain = getSubdomain();

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_HOST + '/api',
  headers: {'Tenant': subdomain}
});

instance.interceptors.response.use(response => {
  return response;
}, error => {
    console.log("Axios interceptor error: ", error);
  if (error.response.status === 403) {
    history.push(`${process.env.PUBLIC_URL}/403`);
  }
  throw error.response.data;
});

export default instance;
