import { useEffect } from 'react';
import buildDateGreaterThan from 'src/utils/buildDateGreaterThan'
import packageJson from '../../package.json';

const ClearCache = () => {
  useEffect(() => {
    console.log('Fetching meta.json');
    fetch(`${process.env.PUBLIC_URL}/meta.json`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
        if (shouldForceRefresh) {
          console.log(`We have a newer version - ${latestVersionDate}. Should force refresh`);
          refreshCacheAndReload();
        } else {
          console.log(`You already have the latest version - ${latestVersionDate}. No cache refresh needed.`);
        }
      });
  }, []);

  const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async function(names) {
        await Promise.all(names.map(name => caches.delete(name)));
      });
    }
    // Delete browser cache and hard reload
    window.location.reload(true);
  }

  return null
}

export default ClearCache;
