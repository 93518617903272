import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from '@material-ui/core';
import {
  Grid as GridIcon,
  MapPin as MapPinIcon,
  BatteryCharging as BatteryChargingIcon,
  Video as VideoIcon,
} from 'react-feather';
import {
  Logo,
  NavBar,
  PoweredBy,
  SplashScreen,
  TopBar
} from '@smileit/react-smile';
import { useSites } from 'src/hooks/site';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 240
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { user, isAuthenticated, logout } = useAuth0();
  const { data: sites } = useSites();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  if (!isAuthenticated) {
    return <SplashScreen />;
  }

  const sections = [
    {
      subheader: '',
      items: [
        {
          title: 'Dashboard',
          icon: GridIcon,
          href: `${process.env.PUBLIC_URL}/dashboard`,
          onClick: () => history.push(`${process.env.PUBLIC_URL}/dashboard`)
        },
        {
          title: 'Sites',
          icon: MapPinIcon,
          href: `${process.env.PUBLIC_URL}/sites`,
          items: sites.map(site => {
            return {
              title: site.name,
              href: `${process.env.PUBLIC_URL}/sites/${site.id}`,
              onClick: () => history.push(`${process.env.PUBLIC_URL}/sites/${site.id}`)
            }
          })
        },
        {
          title: 'Select.Live',
          icon: BatteryChargingIcon,
          isExternal: true,
          href: 'https://select.live/systems',

        },
        {
          title: 'UniFi Protect',
          icon: VideoIcon,
          isExternal: true,
          href: 'https://protect.ui.com/'
        },
      ]
    },
  ];

  const handleLogout = async () => {
    logout({ returnTo: window.location.origin + process.env.PUBLIC_URL });
  };

  return (
    <div className={classes.root}>
      <TopBar
        logo={(
          <Logo
            src={`${process.env.PUBLIC_URL}/static/logo.svg`}
            height="15px"
          />
        )}
        user={user}
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onLogout={handleLogout}
      />
      <NavBar
        sections={sections}
        activePath={location.pathname}
        logo={(
          <Logo
            src={`${process.env.PUBLIC_URL}/static/logo.svg`}
            height="15px"
          />
        )}
        footer={(
          <PoweredBy
            href="https://www.smileit.com.au/"
            src={`${process.env.PUBLIC_URL}/static/sit-logo.svg`}
            alt="Smile IT Logo"
          />
        )}
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
