import React, {
  Fragment,
  Suspense,
  lazy
} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import { AuthGuard, LoadingScreen } from '@smileit/react-smile';

const routes = [
  {
    exact: true,
    path: '/403',
    component: lazy(() => import('src/views/errors/ForbiddenView'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    layout: PublicLayout,
    path: '/public/sites/:siteId',
    component: lazy(() => import('src/views/site/PublicView'))
  },
  {
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('src/views/dashboard/DashboardView'))
      },
      {
        exact: true,
        path: '/sites/:siteId',
        component: lazy(() => import('src/views/site/SiteView'))
      },
      {
        exact: true,
        path: '/sites/:siteId/snapshot',
        component: lazy(() => import('src/views/site/CCTVSnapshotView'))
      },
      {
        exact: true,
        path: '/sites/:siteId/events/:eventId/snapshot',
        component: lazy(() => import('src/views/site/CCTVSnapshotView'))
      },
      {
        exact: true,
        path: '/logout',
        component: lazy(() => import('src/views/auth/LogoutView'))
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
      },
      {
        component: () => <Redirect to={`${process.env.PUBLIC_URL}/404`} />
      }
    ]
  }
];

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path ? process.env.PUBLIC_URL + route.path : null}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default routes;
